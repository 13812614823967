<template>
    <div class="rounded-lg" style="z-index: 200; min-height: 500px;">
        <block v-if="loading"></block>
        <v-stepper v-model="step" class="white pa-4">
            <v-stepper-header style="flex-wrap: nowrap;">
                <v-stepper-step :complete="step > 1" step="1">
                    Carga de datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 2" step="2">
                    Verificación de datos
                </v-stepper-step>
                <v-stepper-step :complete="step > 3" step="3">
                    Previsualización de datos
                </v-stepper-step>
                <v-stepper-step step="4">
                    Procesamiento de datos
                </v-stepper-step>
            </v-stepper-header>

            <div style="margin-top: 1rem; display: flex; flex-direction: row; justify-content: flex-end;">
                <!-- <v-btn class="btnsave" @click="downloadTemplate" :disabled="loading">
                    <v-icon class="mr-2">mdi-download</v-icon>Descargar plantilla para importar
                </v-btn> -->
                <v-btn class="btnclean" @click="resetAll" :disabled="loading || !dataLoaded">
                    <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
                </v-btn>
            </div>
            
            <v-stepper-items>
                
                <!-- Step 1: Upload Json -->
                <v-stepper-content step="1">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">                                
                                <v-card-title class="font-weight-bold text-nowrap">Importar gastos desde archivos JSON</v-card-title>
                                <v-card-actions>
                                    <v-row style="margin-top: 1rem;">
                                        <v-col cols="12" md="6" xs="12">
                                            <div style="margin-bottom: 2rem;">
                                                <input
                                                    id="file-input"
                                                    style="display: none;"
                                                    type="file"
                                                    webkitdirectory
                                                    directory
                                                    multiple
                                                    @click="$event.target.value=''"
                                                    @change="handleFolderFiles($event)"
                                                    :disabled="loading || dataLoaded"
                                                />
                                                <label for="file-input" style="cursor: pointer; width: 100%; border: 1px solid grey; height: 40px; border-radius: 5px; display: flex; align-items: center; justify-content: center;">
                                                    Elegir directorio completo
                                                </label>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <div style="margin-bottom: 2rem;">    
                                                <input
                                                    id="file-input-individual"
                                                    style="display: none;"
                                                    type="file"
                                                    multiple
                                                    accept=".json"
                                                    @click="$event.target.value=''"
                                                    @change="handleIndividualFiles($event)"
                                                    :disabled="loading || dataLoaded"
                                                />
                                                <label for="file-input-individual" style="cursor: pointer; width: 100%; border: 1px solid grey; height: 40px; border-radius: 5px; display: flex; align-items: center; justify-content: center; ">
                                                    Elegir varios archivos individualmente
                                                </label>
                                            </div>
                                        </v-col>
                                        
                                        <v-col cols="12">
                                            <div v-if="selectedFilesNames.length > 0" style="margin: 1rem 0;">
                                                <h4>Archivos seleccionados:</h4>
                                                <v-data-table 
                                                    :headers="[{text: '', value: 'name'}]" 
                                                    :items="selectedFilesNames"
                                                    :items-per-page="5"
                                                    :footer-props="{
                                                        showFirstLastPage: false,                                         
                                                        'items-per-page-options': [],
                                                        'items-per-page-text':'Items por pagina:',
                                                    }"
                                                    class="file-table"
                                                >
                                                </v-data-table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-card-actions style="padding: 1rem; justify-content: flex-end;">
                                    <v-btn v-if="!dataLoaded" class="btn-import" @click="handleFilesUpload" :disabled="loading">
                                        <v-icon class="mr-2">mdi-folder-upload</v-icon>Cargar archivos y continuar
                                    </v-btn>
                                    <v-btn v-else class="btncontinue" @click="nextStep" :disabled="loading">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                
                <!-- Step 2: Verifying data -->
                <v-stepper-content step="2">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Datos de los archivos cargados</v-card-title>
                                <v-card-text v-if="errors" style="display: flex; flex-direction: row;">
                                    <v-icon color="red darken-4" style="margin: 10px; font-size: 20px;" >mdi-alert-circle</v-icon>
                                    <p style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        Algunos proveedores no se encuentran registrados, sus productos no serán procesados.
                                    </p>
                                </v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-checkbox v-model="selectAll" @click.prevent="switchSelectAll" label="Seleccionar todo"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-checkbox v-model="percentageIvaCheck" label="Solo tomar % IVA"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="percentageIvaCheck">
                                            <v-text-field
                                                v-model="percentageIva"
                                                outlined
                                                dense
                                                label="Porcentaje de IVA"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <ClasificationRentaAutocomplete v-model="ClasificationRent" />
                                        </v-col>
                                    </v-row>
                                    <CustomCSVPeriod @customCSVPeriod="setCustomCSVPeriod" @year="setCustomCSVPeriodYear" @month="setCustomCSVPeriodMonth" :cleanState="cleanState" @resetCleanState="() => cleanState = false" :submitState="submitCustomCSVPeriodState" />
                                </v-card-text>
                                <v-data-table 
                                    :headers="headers" 
                                    :items="data"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Items por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <v-icon v-if="item.error" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError(item.error.message)">mdi-alert-circle</v-icon>
                                        <v-icon v-else style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                    <template v-slot:item.toEntry="{ item }">
                                        <v-checkbox class="check" v-model="item.toEntry" @click.prevent="switchEntryData(item)"></v-checkbox>
                                        <!-- <v-icon style="margin: 10px; font-size: 20px;" @click.prevent="deleteFile(item)">mdi-delete</v-icon> -->
                                    </template>
                                    <template v-slot:item.delete="{ item }">
                                        <v-icon style="margin: 10px; font-size: 20px;" @click.prevent="deleteFile(item)">mdi-delete</v-icon>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="nextStep">Continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <!-- Step 3: Preview Data -->
                <v-stepper-content step="3">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Gastos importados de los archivos seleccionados</v-card-title>

                                <AccountingAssign
                                    v-if="$store.getters['rolesUser/getCustomRole'](179)"
                                    v-model="account.id_account"
                                    :assignAll="true"
                                    @accountChanged="accountChanged"
                                    @assignAllAccounts="assignAllAccounts"
                                >
                                </AccountingAssign>
                                
                                <v-data-table 
                                    :headers="expensesHeaders" 
                                    :items="expensesData"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Items por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.accountSearchButton="{ item, index }">
                                        <span class="">
                                            <a @click="chooseAccount( index )">
                                                <v-icon>mdi-magnify</v-icon>
                                            </a>
                                        </span>
                                    </template>
                                    <template v-slot:item.ItemDeclarationType="{ item, index }">
                                        <span class="scroll-fixing-purchase">
                                            <select
                                                style="width: calc(100% - 30px); padding: 5px"
                                                class="form-control custom-select"
                                                v-model="item.ItemDeclarationType"
                                                @change="e => data[ index ].ItemDeclarationType = e.target.value"
                                            >
                                                <option v-for="tipoItem in tipoItemCompraGasto" :value="tipoItem.Codigo" :key="tipoItem.Codigo">
                                                    {{ tipoItem.Valores }}
                                                </option>
                                            </select>
                                            <v-icon>mdi-arrow-down</v-icon>
                                        </span>
                                    </template>
                                </v-data-table>
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep">Anterior</v-btn>
                                    <v-btn class="btnoption" @click="onClickProcess">Procesar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
        
                <!-- Step 4: Process Data -->
                <v-stepper-content step="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card v-if="loading" class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Procesando la información importada</v-card-title>
                                <!-- <v-card-text style="padding: 0 16px;">
                                    <v-progress-linear
                                        v-if="loading"
                                        indeterminate
                                        color="primary"
                                        class="my-4"
                                    ></v-progress-linear>
                                </v-card-text>
                                <div style="height: 50px;"></div> -->
                            </v-card>
                            <v-card v-else class="card-step">
                                <v-card-title class="font-weight-bold text-nowrap">Estado del procesamiento de datos</v-card-title>

                                <v-card-text v-if="!processError && processed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Se han procesado un total de {{ processed.length }} facturas de gastos importadas desde los archivos seleccionados.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showProcessed=!showProcessed" style="min-width: 100px;">
                                            {{!showProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <!-- <v-btn class="btnoption" @click="exportToExcel('SUCCESS')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn> -->
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showProcessed"
                                    :headers="processHeaders" 
                                    :items="processed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Items por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{}">
                                        <v-icon style="margin: 10px; font-size: 20px;">mdi-check-circle</v-icon>
                                    </template>
                                </v-data-table>

                                <v-card-text v-if="!processError && notProcessed.length">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px; color: #e3191e !important;">
                                        No se logro procesar un total de {{ notProcessed.length }} facturas de gastos importadas, debido a la existencia de errores en la información.
                                    </h3>
                                    <v-card-actions style="padding: 0px; gap: 50px;">
                                        <v-btn class="btnoption" @click="showNotProcessed=!showNotProcessed" style="min-width: 100px;">
                                            {{!showNotProcessed ? 'Ver' : 'Ocultar'}}
                                            <v-icon v-if="!showNotProcessed" style="margin-left: 5px;">mdi-menu-down</v-icon>
                                            <v-icon v-else style="margin-left: 5px;">mdi-menu-up</v-icon>
                                        </v-btn>
                                        <!-- <v-btn class="btnoption" @click="exportToExcel('ERROR')" style="background-color: #00a178 !important;">
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn> -->
                                    </v-card-actions>
                                </v-card-text>
                                <v-data-table 
                                    v-if="showNotProcessed"
                                    :headers="processHeaders" 
                                    :items="notProcessed"
                                    :items-per-page="50"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-page-first',
                                        lastIcon: 'mdi-page-last',                                            
                                        'items-per-page-options': [50, 100, 250],
                                        'items-per-page-text':'Productos por pagina:',
                                    }"
                                    class="data-table"
                                >
                                    <template v-slot:item.status="{ item }">
                                        <v-icon v-if="item.error?.provider" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError('Ha ocurrido un error al insertar el proveedor de esta factura de gastos.')">mdi-alert-circle</v-icon>
                                        <v-icon v-else-if="item.error?.isDuplicated" color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError('Esta factura de gastos ya se encuentra registrada en la base de datos.')">mdi-alert-circle</v-icon>
                                        <v-icon v-else color="red darken-4" style="margin: 10px; font-size: 20px;" @click.prevent="showError('Ha ocurrido un error al insertar la factura de gastos de este archivo JSON.')">mdi-alert-circle</v-icon>
                                    </template>
                                </v-data-table>

                                <v-card-text v-if="processError" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        Ha ocurrido un error en el procesamiento, vuelva a intentarlo más tarde. 
                                    </h3> 
                                </v-card-text>

                                <v-card-text v-if="!expensesData.length" style="display: flex; flex-direction: row;">
                                    <h3 style="margin-top: 20px; margin-bottom: 20px; font-size: 16px;">
                                        No existen productos pendiente de procesamiento. 
                                    </h3> 
                                </v-card-text>
                                
                                <v-card-actions style="padding: 1rem; justify-content: space-between;">
                                    <v-btn class="btncontinue" @click="prevStep" :disabled="expensesData.length > 0 || expensesData.length > 0">Anterior</v-btn>
                                    <v-btn class="btncontinue" @click="resetAll">Volver al inicio</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                    
            </v-stepper-items>
        </v-stepper>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        >
        </alerts>
        <v-dialog v-model="showModal" :width="dialogWidth">
            <v-card>
                <AccountingItemForm
                    :win="win"
                    v-on:sendSelection="accountSelectionHandler"
                    v-on:closeDialog="showModal = false"
                />
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Alerts from "@/components/Alerts";
import Block from "@/components/Block";
import AccountingAssign from "./AccountingAssign.vue";
import * as XLSX from "xlsx";
import CustomCSVPeriod from "../../../components/Misc/CustomCSVPeriod.vue";
import ClasificationRentaAutocomplete from "../../../components/Misc/ClasificationRentaAutocomplete.vue";
import { mapGetters } from "vuex";
import AccountingItemForm from "./AccountingItemForm.vue";

export default {
    name: "ExpenseManagmentMassiveEntry",
    props: ["win"],
    components: {
        Block,
        Alerts,
        AccountingAssign,
        CustomCSVPeriod,
        ClasificationRentaAutocomplete,
        AccountingItemForm
    },
    data() {
        return {
            step: 1,
            loading: false,
            dataLoaded: false,
            selectAll: true,
            percentageIvaCheck: false,
            percentageIva: 50,
            id_bussiness: null,
            bussiness: null,
            id_branch: null,
            id_user: null,
            providers: [],
            selectedFiles: [],
            selectedFilesNames: [],
            currentJson: null,
            dataFiles: [],
            headerFiles: [],
            data: [],
            headers: [],
            expensesData: [],
            expensesHeaders: [],
            processHeaders: [],

            accountList: [],
            account: {
                id_account: null
            },

            errors: null,
            processError: false,

            processed: [],
            notProcessed: [],
            showNotProcessed: false,
            showProcessed: false,
           
            invoiceType: [null, "FCF", "CCF", "FNS", "FEX"],
            paymentMethod: [ "Efectivo", "Cheque", "Transferencia", "Tarjeta de Crédito", "Otro" ],
            invoiceStatus: ["Pendiente de pago", "Cancelada", "Crédito", "Anulada"],
            days: ['8 Dias', '15 Dias', '30 Dias', '90 Dias', '120 Dias', '180 Dias'],

            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            CustomCSVPeriodFlag: false,
            CustomCSVPeriodYear: null,
            CustomCSVPeriodMonth: null,
            ClasificationRent: '2',
            submitCustomCSVPeriodState: false,
            cleanState: false,
            targetIndex: null,
            showModal: false
        };
    },
    async mounted() {
        this.id_bussiness = JSON.parse(localStorage.getItem("user")).businessid;
        this.business = JSON.parse(localStorage.getItem("user")).business;
        this.id_branch = JSON.parse(localStorage.getItem("user")).branch;
        this.id_user = JSON.parse(localStorage.getItem("user")).id;
        await this.getAllProviders();
        await this.getAllAccounts();
        
        this.headers = [
            {text: '' , value: 'toEntry'},
            {text: 'Estado' , value: 'status'},
            {text: 'Fecha' , value: 'fecEmi'},
            {text: 'Número Control' , value: 'numeroControl'},
            {text: 'Codigo Generación' , value: 'codigoGeneracion'},
            {text: 'Tipo de factura' , value: 'invoiceType'},
            {text: 'ID Proveedor' , value: 'provider'},
            {text: 'NIT Proveedor' , value: 'nit'},
            {text: 'NRC Proveedor' , value: 'nrc'},
            {text: 'Nombre Proveedor' , value: 'nombre'},
            {text: 'Nombre Comercial Proveedor' , value: 'nombreComercial'},
            
            {text: 'ivaRete1' , value: 'ivaRete1'},
            {text: 'ivaPerci1' , value: 'ivaPerci1'},
            {text: 'totalIva' , value: 'totalIva'},
            {text: 'reteRenta' , value: 'reteRenta'},
            {text: 'descuNoSuj' , value: 'descuNoSuj'},
            {text: 'descuExenta' , value: 'descuExenta'},
            {text: 'descuGravada' , value: 'descuGravada'},
            {text: 'porcentajeDescuento' , value: 'porcentajeDescuento'},
            {text: 'totalDescu' , value: 'totalDescu'},
            {text: 'totalNoSuj' , value: 'totalNoSuj'},
            {text: 'totalExenta' , value: 'totalExenta'},
            {text: 'totalGravada' , value: 'totalGravada'},
            {text: 'totalNoGravado' , value: 'totalNoGravado'},
            {text: 'subTotalVentas' , value: 'subTotalVentas'},

            {text: 'Total Operación' , value: 'montoTotalOperacion'},
            {text: 'Total a Pagar' , value: 'totalPagar'},
            {text: '' , value: 'delete'},
        ];
        this.expensesHeaders = [
            {text: '' , value: 'accountSearchButton'},
            {text: 'Código' , value: 'account_code'},
            {text: 'Cuenta' , value: 'account_name'},
            {text: 'Fecha' , value: 'fecEmi'},
            {text: 'Número control' , value: 'numeroControl'},
            {text: 'Codigo generación' , value: 'codigoGeneracion'},
            {text: 'Tipo de factura' , value: 'invoiceType'},
            {text: 'Proveedor' , value: 'provider'},
            {text: 'Nombre del gasto' , value: 'descripcion'},
            {text: 'Precio' , value: 'precioUni'},
            {text: 'Cantidad' , value: 'cantidad'},
            {text: 'Estado' , value: 'condicionOperacion'},
            {text: 'Gasto declarable como:' , value: 'ItemDeclarationType'},
            {text: 'Descuento' , value: 'montoDescu'},
            {text: 'Total no sujeto' , value: 'ventaNoSuj'},
            {text: 'Total exento' , value: 'ventaExenta'},
            {text: 'Total gravado' , value: 'ventaGravada'},
            {text: 'Total a pagar' , value: 'totalPagar'},
            {text: 'Cuenta Contable' , value: 'account_code'},
        ];

        this.processHeaders = [
            {text: 'Estado' , value: 'status'},
            {text: 'Fecha' , value: 'fecEmi'},
            {text: 'Número Control' , value: 'numeroControl'},
            {text: 'Codigo Generación' , value: 'codigoGeneracion'},
            {text: 'Tipo de factura' , value: 'invoiceType'},
            {text: 'ID Proveedor' , value: 'provider'},
            {text: 'NIT Proveedor' , value: 'nit'},
            {text: 'NRC Proveedor' , value: 'nrc'},
            {text: 'Nombre Proveedor' , value: 'nombre'},
            {text: 'Nombre Comercial Proveedor' , value: 'nombreComercial'},
            {text: 'Sub Total' , value: 'subTotal'},
            {text: 'Total Operación' , value: 'montoTotalOperacion'},
            {text: 'Total a Pagar' , value: 'totalPagar'},
        ];
    },
    watch: {
        /* tableData: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                this.errors = newValue.some((item) => item.error);
            }
        }, */
        selectedFiles: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                for (const item of newValue) {
                    this.selectedFilesNames.push({name: item.name});
                }
            }
        },
        data: {
            deep: true,
            inmediate: true,
            handler(newValue) {
                for (const item of newValue) {
                    if(item.toEntry == false) {
                        this.selectAll = false;
                    }
                }
            }
        },
        
    },
    computed: {
        ...mapGetters(["getCatalogMH"]),
        dialogWidth() {
            return this.$vuetify.breakpoint.smAndDown ? "95%" : "60%";
        },
        tipoItemCompraGasto() {
            return this.getCatalogMH("TipoItemCompraGasto");
        },
    },
    methods: {
        chooseAccount(index) {
            this.showModal =true
            this.targetIndex = index;
        },
        accountSelectionHandler(data) {
            this.showModal = false;
            const { id_account, account_code, account_description } = data;

            this.$set(this.expensesData[ this.targetIndex ], 'id_account', id_account);
            this.$set(this.expensesData[ this.targetIndex ], 'account_code', account_code);
            this.$set(this.expensesData[ this.targetIndex ], 'account_name', account_description);
            this.data[ this.targetIndex ].id_account = id_account;
            this.data[ this.targetIndex ].account_code = account_code;
            this.data[ this.targetIndex ].account_name = account_description;
        },
        accountChanged( e ) {
            this.account.id_account = e
        },
        setCustomCSVPeriod(val){
            this.CustomCSVPeriodFlag = val
        },
        setCustomCSVPeriodYear(val){
            this.CustomCSVPeriodYear = val
        },
        setCustomCSVPeriodMonth(val){
            this.CustomCSVPeriodMonth = val
        },
        nextStep() {
            if(this.step === 1) {
                if(this.selectedFiles.length && !this.dataLoaded) {
                    this.showAlert("warning", "Advertencia", "Antes de continuar debe cargar los archivos seleccionados.");
                    return;
                }
            }
            if(this.step == 2) {
                let errors = 0;

                if( this.CustomCSVPeriodFlag ) {
                    this.submitCustomCSVPeriodState = true;
                    !this.CustomCSVPeriodMonth && ( errors += 1 );
                    !this.CustomCSVPeriodYear && ( errors += 1 );
                }

                if (errors > 0) {
                    this.showAlert(
                        "warning",
                        "Advertencia",
                        "Debe llenar todos los campos obligatorios"
                    );
                    return;
                }

                this.handleExpensesDetails();
            }
            this.step++;
        },
        prevStep() {
            this.step--;
        },
        async getAllProviders() {
            await this.$API.accountingexpenses.getAllProvidersExpenses()
                .then((res) => {
                    this.providers = res.data;
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        handleFolderFiles(event) {
            this.resetData();
            const files = Array.from(event.target.files);
            const jsonFiles = files.filter(file => file.name.endsWith('.json'));

            if (jsonFiles.length > 0) {
                this.selectedFiles = [...jsonFiles];
            } else {
                alert('No se encontraron archivos JSON en el directorio seleccionado.');
            }
        },
        handleIndividualFiles(event) {
            //console.log('----here----', event);
            this.resetData();
            const files = Array.from(event.target.files);
            const jsonFiles = files.filter(file => file.name.endsWith('.json') || file.name.endsWith('.JSON'));

            if (jsonFiles.length > 0) {
                this.selectedFiles = [...this.selectedFiles, ...jsonFiles];
            } else {
                alert('No se encontraron archivos JSON seleccionados.');
            }
        },
        async handleFilesUpload() {
            if (!this.selectedFiles.length) {
                this.showAlert("warning", "Advertencia", "Debe seleccionar los archivos a cargar.");
                return;
            }
            this.loading = true;
            try {
                // Procesar los archivos JSON seleccionados
                for (const file of this.selectedFiles) {
                    if (file.type === "application/json") {
                        const content = await this.readFile(file);
                        this.dataFiles.push(JSON.parse(content));
                    }
                }

                for (const item of this.dataFiles) {
                    this.currentJson = item.identificacion.codigoGeneracion;                    
                    item.toEntry = true;
                    const provider = this.checkProvider(item.emisor);
                    if(!provider){
                        //console.log('Not checked');
                        const newProvider = await this.addProvider({ ...item.emisor , ivaPerci1: item.resumen.ivaPerci1 });
                        if(newProvider && newProvider.ID_Provider) {
                            item.provider = newProvider.ID_Provider;
                            item.id_account = newProvider.id_purchases_account ? newProvider.id_purchases_account : null;
                            //this.providers = await this.getAllProviders();
                        }
                        else {
                            item.error = {
                                providerError: true,
                                message: 'No se pudo insertar el proveedor de esta factura, intentelo nuevamente más tarde.',
                            };
                            item.toEntry = false;
                        }
                    }
                    else {
                        //console.log('Provider checked', provider);
                        item.provider = provider.ID_Provider;
                        item.id_account = provider.id_purchases_account ? provider.id_purchases_account : null;
                        item.account_code = provider.id_purchases_account ? this.accountList.find(i => i.id_account == provider.id_purchases_account)?.account_code : null;
                        item.account_description = provider.id_purchases_account ? this.accountList.find(i => i.id_account == provider.id_purchases_account)?.account_description : null;
                    }

                    const isDuplicated = await this.checkDuplicatedEntries(item.identificacion.codigoGeneracion);
                    if(isDuplicated) {
                        item.error = {
                            isDuplicated,
                            message: item.error?.message ? item.error?.message : 'Esta factura ya se encuentra registrada, si desea sobreescribir la información existente debera seleccionar el checkbox.'
                        };
                        item.toEntry = false;
                    }

                    if(item.identificacion.tipoDte == "03") item.invoiceType = "CCF";
                    if(item.identificacion.tipoDte == "11") item.invoiceType = "FEX";
                    if(item.identificacion.tipoDte == "14") item.invoiceType = "FNS";
                    if(item.identificacion.tipoDte == "01") item.invoiceType = "FCF";

                    let tributos = {
                        iva: 0,
                    };

                    if(item.resumen.tributos && item.resumen.tributos.length) {
                        let index = 11;
                        for (const el of item.resumen.tributos) {
                            if(el.codigo == '20' || el.descripcion.includes('IVA')){
                                tributos.iva = el.valor;
                                if((this.headers.some(i => i.value == 'iva')) !== true) {
                                    this.headers.splice(index, 0, {text: 'IVA' , value: 'iva'});
                                    index += 1;
                                }
                            }
                            else if(el.codigo == 'D1' || el.descripcion.includes('FOVIAL')){
                                tributos.fovial = el.valor;
                                if((this.headers.some(i => i.value == 'fovial')) !== true) {
                                    this.headers.splice(index, 0, {text: 'Fovial' , value: 'fovial'});
                                    index += 1;
                                }
                            }
                            else if(el.codigo == 'C8' || el.descripcion.includes('COTRANS')){
                                tributos.cotrans = el.valor;
                                if((this.headers.some(i => i.value == 'cotrans')) !== true) {
                                    this.headers.splice(index, 0, {text: 'Cotrans' , value: 'cotrans'});
                                    index += 1;
                                }
                            }
                            else {
                                tributos[el.descripcion] = el.valor;
                                if((this.headers.some(i => i.value == el.descripcion)) !== true) {
                                    this.headers.splice(index, 0, {text: el.descripcion , value: el.descripcion});
                                    index += 1;
                                }
                            }
                        }
                    }
                    if(item.invoiceType == "FEX") {
                        item.flete = item.resumen.flete;       
                        item.seguro = item.resumen.seguro;       
                    }

                    let rowData = {
                        ...item,
                        ivaRete1: item.resumen.ivaRete1,
                        ivaPerci1: item.resumen.ivaPerci1,
                        totalIva: item.resumen.totalIva,
                        reteRenta: item.resumen.reteRenta,
                        descuNoSuj: item.resumen.descuNoSuj,
                        descuExenta: item.resumen.descuExenta,
                        descuGravada: item.resumen.descuGravada,
                        porcentajeDescuento: item.resumen.porcentajeDescuento,
                        totalDescu: item.resumen.totalDescu,
                        totalNoSuj: item.resumen.totalNoSuj,
                        totalExenta: item.resumen.totalExenta,
                        totalGravada: item.resumen.totalGravada,
                        totalNoGravado: item.resumen.totalNoGravado,
                        subTotalVentas: item.resumen.subTotalVentas,
                        fecEmi: this.formatDate(item.identificacion.fecEmi),
                        numeroControl: item.identificacion.numeroControl,
                        codigoGeneracion: item.identificacion.codigoGeneracion,
                        invoiceType: item.invoiceType,
                        provider: item.provider,
                        nit: item.emisor.nit,
                        nrc: item.emisor.nrc,
                        nombre: item.emisor.nombre,
                        nombreComercial: item.emisor.nombreComercial,
                        subTotal: item.resumen.subTotal,
                        //iva: tributos.iva,
                        montoTotalOperacion: item.resumen.montoTotalOperacion,
                        totalPagar: item.resumen.totalPagar,
                        toEntry: item.toEntry,
                        error: item.error || null,
                        dteJson: item,
                        ItemDeclarationType: 2,
                    };
                    
                    for (const prop in tributos) {
                        rowData = {
                            ...rowData,
                            [prop]: tributos[prop]
                        }
                    }
                    
                    this.data.push(rowData);
                }
                setTimeout(() => {
                    this.loading = false;
                    this.dataLoaded = true;
                    this.currentJson = null; 
                    //console.log(this.data);          
                    this.showAlert("success", "Exito", "Los archivos seleccionados fueron cargados correctamente.", "continueStep");
                }, 1000);
            } 
            catch (error) {
                console.log(this.selectedFilesNames);
                
                const fileName = this.selectedFilesNames.find(item => item.name.includes(this.currentJson));
                console.log(error.message);
                this.loading = false;
                if(fileName && fileName.name) {
                    this.showAlert("warning", "Advertencia", `Ha ocurrido un error al cargar el archivo ${fileName.name}, revise la estructura del JSON.`);
                }
                else {
                    this.showAlert("warning", "Advertencia", "Ha ocurrido un error al cargar los archivos, vuelva a intentarlo más tarde.");
                }
                this.currentJson = null; 
            }
        },
        async handleExpensesDetails() {
            this.loading = true;
            const expenses = [];
            for (const item of this.dataFiles) {                                                
                if(item.toEntry === true && !item.error?.providerError) {
                    const headerExpense = {
                        fecEmi: this.formatDate(item.identificacion.fecEmi),
                        numeroControl: item.identificacion.numeroControl,
                        codigoGeneracion: item.identificacion.codigoGeneracion,
                        documentType: 2,
                        invoiceType: item.invoiceType,
                        provider: item.provider,
                        subTotal: item.resumen.subTotal,
                        montoTotalOperacion: item.resumen.montoTotalOperacion,
                        totalPagar: item.resumen.totalPagar,
                        totalLetras: item.resumen.totalLetras,
                        ivaPerci1: item.resumen.ivaPerci1,
                        ivaRete1: item.resumen.ivaRete1,
                        reteRenta: item.resumen.reteRenta,
                        condicionOperacion: this.invoiceStatus[item.resumen.condicionOperacion],
                        id_account: item.id_account,
                        account_code: item.account_code,
                        account_name: item.account_description,
                    };
                    
                    for (const element of item.cuerpoDocumento) {
                        const expense = {
                            ...headerExpense,
                            descripcion: element.descripcion,
                            precioUni: element.precioUni,
                            cantidad: element.cantidad,
                            montoDescu: element.montoDescu,
                            ventaNoSuj: element.ventaNoSuj,
                            ventaExenta: element.ventaExenta,
                            ventaGravada: element.ventaGravada,
                            ItemDeclarationType: 2
                        };
                        if(expense.ventaNoSuj != 0 && expense.ventaExenta == 0 && expense.ventaGravada == 0) expense.total = expense.ventaNoSuj;
                        else if(expense.ventaNoSuj == 0 && expense.ventaExenta != 0 && expense.ventaGravada == 0) expense.total = expense.ventaExenta;
                        else expense.total = expense.ventaGravada;

                        expenses.push(expense);
                    }
                }
            }
            this.expensesData = expenses;
            this.loading = false;
        },
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(event.target.result);
                reader.onerror = (error) => reject(error);
                reader.readAsText(file);
            });
        },
        checkProvider(item) {            
            return this.providers.find(el => el.ProviderLegalNIT.replaceAll("-","") == item.nit);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            const newDate = new Date(`${month}/${day}/${year}`)
            return this.moment(newDate).format('DD/MM/YYYY');
        },
        async addProvider(data) {
            //console.log(data);
            const providerNIT = data.nit ? data.nit.toString().replaceAll("-","") : null;
            const providerNRC = data.nrc ? data.nrc.toString().replaceAll("-","") : null;

            const provider = {
                ID_Provider: null,
                ProviderName: data.nombre,
                ProviderLegalName: data.nombreComercial,
                ProviderPhone: data.telefono ? data.telefono.toString().replaceAll("-","") : null,
                ProviderEmail: data.correo,
                ProviderLegalAddress: data.direccion.complemento,
                ProviderLegalSector: data.codActividad,
                ProviderLegalTaxPayerSize: "",
                ProviderLegalEntity: "",
                ProviderLegalNRC: providerNRC,
                ProviderLegalNIT: providerNIT,
                Notes: 'Insertado autamaticamente por importación de gastos',
                ID_Business: this.id_bussiness,
                ID_Branch: this.id_branch,
                ID_User_Insert: this.id_user,
                LegalAgentRetain: data.ivaPerci1 > 0 ? 1 : 0,            
            };
            
            return await this.$API.accountingexpenses.saveProvidersExpenses(provider)
                .then(async (response) => {
                    await this.getAllProviders();
                    return response.data;
                })
                .catch((error) => {
                    console.log(error)
                    throw new Error('Ha ocurrido un error al insertar los proveedores, intentelo de nuevo más tarde.');
                });
        },
        async checkDuplicatedEntries(value) {
            return await this.$API.accountingexpensesentry.verifyDuplicatedEntries({ expenseId: value })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        getAllAccounts() {
            this.$API.branchaccountcatalog.getAssignableAccounts()
                .then((response) => {
                    this.accountList = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        assignAllAccounts() {       
            const found = this.accountList.find((current) => current.id_account == this.account.id_account);        
            for (const item of this.expensesData) {
                if (found) {
                    /* item.id_account = found.id_account;
                    item.account_code = found.account_code;
                    item.account_name = found.account_description; */
                    this.$set(item, 'id_account', found.id_account);
                    this.$set(item, 'account_code', found.account_code);
                    this.$set(item, 'account_name', found.account_description);
                } 
            }
            for (const item of this.data) {
                if (found) {
                    item.id_account = found.id_account;
                    item.account_code = found.account_code;
                    item.account_name = found.account_description;
                    /* this.$set(item, 'id_account', found.id_account);
                    this.$set(item, 'account_code', found.account_code);
                    this.$set(item, 'account_name', found.account_description); */
                } 
            }
            this.$forceUpdate();            
            this.showAlert("success", "Exito", `Se ha actualizado la tabla asignando la cuenta seleccionada a los gastos importados.`);
        },
        async processData() {
            this.loading = true;
            this.nextStep();
            try {
                if(!this.expensesData.length || !this.data.length) throw new Error('No ha seleccionado archivo con la información.');
                                 
                for (const item of this.data) {
                    const formData = new FormData();
                    const expensesEntryDetail = [];

                    if(item.toEntry === true && !item.error?.providerError) {
                        const expensesEntry = {
                            ID_Provider: item.provider,
                            SubTotal: item.subTotal,
                            TotalExpense: item.totalPagar,
                            ExpenseInvoiceDate: item.fecEmi,
                            ExpenseInvoiceType: item.invoiceType,
                            ExpenseInvoiceDocumentType: 2,
                            ExpenseInvoiceId: item.codigoGeneracion,
                            ExpenseInvoicePayment: null,
                            ExpenseInvoiceStatus: this.invoiceStatus[item.resumen.condicionOperacion],
                            ExpenseInvoiceCreditDays: null,
                            ExpenseInvoiceComment: '',
                            Iva: item.iva ? item.iva : 0,
                            Exentas: item.resumen.totalExenta,
                            NoSujetas: item.resumen.totalNoSuj,
                            ReteRenta: item.resumen.reteRenta,
                            IvaRet: item.resumen.ivaRete1,
                            IvaPerc: item.resumen.ivaPerci1,
                            //ID_ExpenseEntry: item...,
                            ID_AccountCatalog: item.id_account,
                            CatalogName: item.account_name,
                            Fovial: item.fovial ? item.fovial : 0,
                            Cotrans: item.cotrans ? item.cotrans : 0,
                            Flete: item.flete ? item.flete : 0,
                            Seguro: item.seguro ? item.seguro : 0,
                            DteJson: item.dteJson,
                            PercentageIVA: this.percentageIvaCheck ? this.percentageIva : null,
                            isDuplicated: item.error?.isDuplicated ? true : false,
                            CustomCSVPeriodFlag: this.CustomCSVPeriodFlag,
                            CustomCSVPeriodYear: this.CustomCSVPeriodYear,
                            CustomCSVPeriodMonth: this.CustomCSVPeriodMonth,
                            ClasificationRent: this.ClasificationRent
                        };

                        
                        for (const el of item.cuerpoDocumento) {
                            const expensesDetails = {
                                ID: null,
                                ExpenseInvoiceId: item.codigoGeneracion,
                                NameExpense: el.descripcion,
                                UnitPrice: el.precioUni,
                                Quantity: el.cantidad,
                                ID_AccountCatalog: el.id_account ? el.id_account : item.id_account,
                                CatalogName: el.account_name ? el.account_name : item.account_name,
                                ItemDeclarationType: item.ItemDeclarationType
                            }

                            if(el.ventaNoSuj != 0 && el.ventaExenta == 0 && el.ventaGravada == 0) {
                                expensesDetails.Total = el.ventaNoSuj;
                                expensesDetails.IsNoSujeto = 1;
                            }
                            else if(el.ventaNoSuj == 0 && el.ventaExenta != 0 && el.ventaGravada == 0) {
                                expensesDetails.Total = el.ventaExenta;
                                expensesDetails.IsExento = 1;
                            }
                            else expensesDetails.Total = el.ventaGravada;

                            expensesEntryDetail.push(expensesDetails);
                        }                        
                        
                        formData.append("expensesEntry", JSON.stringify(expensesEntry));
                        formData.append("expensesEntryDetail", JSON.stringify(expensesEntryDetail));
                        await this.$API.accountingexpensesentry.ImportAccountingExpenses(formData)
                            .then((res) => {
                                if(res.success) {
                                    this.processed.push(item);
                                }
                            })
                            .catch((error) => {
                                this.notProcessed.push(item);
                                //this.processError = true;
                                console.log(error);
                                //throw new Error();
                            });                
                    }
                    else {
                        this.notProcessed.push(item);
                    }
                }
                this.loading = false;
                if(this.data.length == this.processed.length) {
                    this.showAlert("success", "Gastos contables importados", "Los gastos importados fueron agragados correctamente.");
                }
                else if(this.data.length == this.notProcessed.length) {
                    this.showAlert("warning", "Gastos contables importados", "Los gastos importados no pudieron procesarse debido a la existencia de errores.");
                }
                else if(this.data.length != this.processed.length) {
                    this.showAlert("warning", "Gastos contables importados", "Algunos gastos importados no pudieron procesarse debido a la existencia de errores.");
                }
            } 
            catch (error) {
                this.loading = false;
                this.processError = true;
                this.showAlert("danger", "Error", error.message || "Ocurrio un error en el procesamiento de la información.");
            }
        },
        exportToExcel(value) {
            if(value === 'ERROR') {
                const headers = this.tableHeaders.map(header => header.text);
                const data = this.productsNotAllowed.map(item => this.tableHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla inventario');
    
                XLSX.writeFile(workbook, 'plantilla_inventario_errores.xlsx');

            } else if(value === 'SUCCESS') {
                const headers = this.expensesHeaders.map(header => header.text);
                const data = this.processed.map(item => this.expensesHeaders.map(header => item[header.value]));
    
                const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Gastos importados');
    
                XLSX.writeFile(workbook, 'Gastos_importados.xlsx');

            }
        },
        switchEntryData(item) {
            if(!item) return;
            
            for (const element of this.dataFiles) {
                if(element.identificacion.numeroControl == item.numeroControl && element.identificacion.codigoGeneracion == item.codigoGeneracion) {
                    element.toEntry = !element.toEntry;
                }
            }
        },
        switchSelectAll() {
            for (const item of this.data) {
                    item.toEntry = this.selectAll;
                }
            for (const item of this.dataFiles) {
                item.toEntry = this.selectAll;
            }
        },
        deleteFile(file) {
            console.log(file);
            this.data.filter((item) => item.numeroControl != file.numeroControl && item.codigoGeneracion != file.codigoGeneracion);
            this.dataFiles.filter((item) => item.identificacion.numeroControl != file.numeroControl && item.identificacion.codigoGeneracion != file.codigoGeneracion);
        },
        onClickProcess() {
            this.showAlert("question2", "Advertencia", "¿Esta seguro que desea continuar?", "processData");
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        closeAlert() {
            this.alert.show = false;
            if (this.alert.options == 'continueStep') {
                this.nextStep();
            }
        },
        acceptAlert() {
            this.alert.show = false;
            if (this.alert.options == 'processData') {
                this.processData();
            }
            if (this.alert.options == 'continueStep') {
                this.nextStep();
            }
        },
        showError(message) {
            this.showAlert("warning", "Advertencia", message);
        },
        resetData() {
            this.dataLoaded = false;
            this.selectAll = true;
            this.selectedFiles = [];
            this.selectedFilesNames = [];
            this.dataFiles = [];
            this.data = [];
            this.expensesData = [];
            this.errors = null;
            this.processError = false;
            this.percentageIvaCheck = false;
            this.percentageIva = 50;
            this.getAllProviders();
        },
        resetAll() {
            this.step = 1;
            this.loading = false;
            this.dataLoaded = false;
            this.selectAll = true;
            this.selectedFiles = [];
            this.selectedFilesNames = [];
            this.dataFiles = [];
            this.data = [];
            this.expensesData = [];
            this.errors = null;
            this.processError = false;
            this.processed = [];
            this.notProcessed = [];
            this.showNotProcessed = false;
            this.showProcessed = false;
            this.account = {};
            this.percentageIvaCheck = false;
            this.percentageIva = 50;
            this.getAllProviders();
            this.cleanState = true;
        },
    }
};
</script>

<style scoped>
.card-step {
    min-height: 400px; 
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
}
.col-input-file {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 1rem;
}
.btn-import {
  background-color: #3498db !important;
  color: white;
  min-width: 250px !important;
  height: 40px !important;
}
.btnoption {
  background-color: #3498DB !important;
  color: white;
}
.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}
.btndisable {
    background-color: #41464c !important;
    color: white;
}
</style>

<style>
.mdi-file-excel::before {
    font-size: 32px !important;
}
.data-table .v-data-table__wrapper thead th {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.data-table .v-data-table__wrapper tbody tr {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.data-table .v-data-table__wrapper tbody td {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
}
.file-table .v-data-table__wrapper thead th {
    height: 5px !important;
    text-wrap: nowrap;
    min-width: 100px;
    border: 0px !important;
}
.file-table .v-data-table__wrapper tbody tr {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
    border: 0px !important;
}
.file-table .v-data-table__wrapper tbody td.text-start {
    padding: 0px;
    border: 0px !important;
}
.file-table .v-data-table__wrapper tbody td {
    height: 35px !important;
    text-wrap: nowrap;
    min-width: 100px;
    border: 0px !important;
}
.tabs-seg .v-slide-group__content {
    justify-content: space-between;
}
.check .v-input__control {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
}

</style>