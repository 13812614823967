<template>
  <v-autocomplete outlined dense attach :rules="rules" label="Clasificación (Renta)" v-model="clasification" :items="comprasClasificacionRenta" item-text="Valores" item-value="Codigo" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'ClasificationRentaAutocomplete',
    props: [ 'value' ],
    data() {
      return {
        rules: [(v) => v !== null || "Valor es requerido"],
      }
    },
    computed: {
      clasification: {
        get() {
          return this.value;
        },
        set( v ) {
          this.$emit( 'input', v );
        }
      },
      ...mapGetters(["getCatalogMH"]),
      comprasClasificacionRenta() {
        return this.getCatalogMH('ComprasClasificacionRenta')
      }
    }
}
</script>

<style>

</style>