<template>
  <div>
    <v-expand-transition>
        <v-row class="mb-8" v-show="!displayFlagCptd">
            <v-col cols="0" md="4">
    
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center">
                <v-checkbox
                    hide-details
                    v-model="displayFlagCptd" label="Período Personalizado Para CSV's"
                    v-bind="attrs"
                    v-on="on"
                ></v-checkbox>
                <!-- <div class="black--text">Período Contable Personalizado</div> -->
            </v-col>
            <v-col cols="0" md="4">
    
            </v-col>
        </v-row>
    </v-expand-transition>
    <v-expand-transition>
        <v-form v-model="valid" ref="customCSVPeriodForm" v-show="displayFlagCptd">

            <v-row class="mb-8">
                <v-col cols="12" md="12">
                    <v-card class="pt-1">
                        <v-row class="mt-1 ml-1 mr-1">
                            <v-col cols="12" md="4">
                                <v-checkbox
                                    hide-details
                                    v-model="displayFlagCptd" class="text-h6 black--text" label="Período Personalizado Para CSV's"
                                >
                                </v-checkbox>
                                <!-- <div >Período Personalizado Para CSV's</div> -->
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    v-model="monthCptd"
                                    :items="monthList"
                                    item-text="text"
                                    item-value="value"
                                    label="Mes"
                                    :rules="rules"
                                    outlined
                                    attach
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="yearCptd"
                                    label="Año"
                                    outlined
                                    :rules="rules"
                                    type="number"
                                    attach
                                    dense
                                    clearable
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
    name: "CustomCSVPeriod",
    props: [ "cleanState", 'submitState' ],
    data() {
        return {
            rules: [(v) => v !== null || "Valor es requerido"],
            displayFlag: false,
            month: null,
            year: null,
            monthList: [
				{text: 'Enero', value: 1},
				{text: 'Febrero', value: 2},
				{text: 'Marzo', value: 3},
				{text: 'Abril', value: 4},
				{text: 'Mayo', value: 5},
				{text: 'Junio', value: 6},
				{text: 'Julio', value: 7},
				{text: 'Agosto', value: 8},
				{text: 'Septiembre', value: 9},
				{text: 'Octubre', value: 10},
				{text: 'Noviembre', value: 11},
				{text: 'Diciembre', value: 12},
			],
        }
    },
    computed: {
        displayFlagCptd: {
            set( val ) {
                this.displayFlag = val;
                this.$emit( 'customCSVPeriod', this.displayFlag );
            },
            get() {
                return this.displayFlag;
            }
        },
        monthCptd: {
            set( val ) {
                this.month = val;
                this.$emit( 'month', this.month );
            },
            get() {
                return this.month;
            }
        },
        yearCptd: {
            set( val ) {
                this.year = val;
                this.$emit( 'year', this.year );
            },
            get() {
                return this.year;
            }
        }
    },
    watch: {
        cleanState( value ) {
            if( value ) {
                this.displayFlag = false;
                this.month = null;
                this.year = null;
                this.$emit( 'resetCleanState' );
            }
        },
        submitState( value ) {
            if( value ) {
                this.$refs.customCSVPeriodForm.validate();
            }
        }
    }
}
</script>

<style>

</style>